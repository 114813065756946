import React, { useCallback, useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import classnames from 'classnames'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Form, Grid, Message, Progress, Button, Icon, Accordion, Modal } from 'semantic-ui-react'
import ReCAPTCHA from 'react-google-recaptcha'
import Vimeo from 'react-player/vimeo'

import SEO from '../../components/SEO'
import Carousel from '../../components/Carousel'
import TextInput from '../../components/TextInput'

import Logo from '../../images/logos/contego-logo.png'

// contact services
import { submitLandingPageForm } from '../../services/bullarum.service'

import useTimeoutMessage from '../../hooks/useTimeoutMessage'
import { callHandler } from '../../ultilities'

// define contact us schema for validation
const ContactSchema = Yup.object().shape({
  customerName: Yup.string()
    .required('Your name is required')
    .min(2, 'Invalid input')
    .max(25, 'Invalid input')
    .trim(),
  phoneNumber: Yup.string()
    .matches(/[0-9]{10}$/, 'Invalid input')
    .max(10, 'Invalid input')
    .required('Please enter your phone number!!!')
})

const defaultValues = {
  customerName: '',
  phoneNumber: ''
}

const ownerReviews = [
  {
    index: 0,
    videoLink: 'https://player.vimeo.com/video/432982071'
  },
  { index: 1, videoLink: 'https://player.vimeo.com/video/432982030' },
  { index: 2, videoLink: 'https://player.vimeo.com/video/432981995' },
  { index: 3, videoLink: 'https://player.vimeo.com/video/432982172' },
  { index: 4, videoLink: 'https://player.vimeo.com/video/432982198' },
  { index: 5, videoLink: 'https://player.vimeo.com/video/432982273' },
  { index: 6, videoLink: 'https://player.vimeo.com/video/432982390' }
]

const techReviews = [
  {
    index: 0,
    videoLink: 'https://player.vimeo.com/video/432983019'
  },
  {
    index: 1,
    videoLink: 'https://player.vimeo.com/video/432982613'
  },
  {
    index: 2,
    videoLink: 'https://player.vimeo.com/video/432982930'
  },
  {
    index: 3,
    videoLink: 'https://player.vimeo.com/video/432982523'
  },
  {
    index: 4,
    videoLink: 'https://player.vimeo.com/video/432983086'
  }
]

const chairVideos = [
  {
    index: 0,
    videoLink: 'https://player.vimeo.com/video/439181387',
    model: 'VIP BENCH'
  },
  {
    index: 2,
    videoLink: 'https://player.vimeo.com/video/439072433',
    model: 'TERGO'
  },
  {
    index: 3,
    videoLink: 'https://player.vimeo.com/video/439072521',
    model: 'VSPORT.N'
  },
  {
    index: 4,
    videoLink: 'https://player.vimeo.com/video/439072492',
    model: 'VSPORT.T'
  },
  {
    index: 5,
    videoLink: 'https://player.vimeo.com/video/439072540',
    model: 'MAGNA'
  }
]

const faqs = [
  {
    index: 0,
    question: 'Ghế Contego trong tiệm nails nhìn ra sao?',
    url: 'https://player.vimeo.com/video/439908167'
  },
  {
    index: 1,
    question: 'Công ty có bán furniture hay không?',
    url: 'https://player.vimeo.com/video/439408088'
  },
  {
    index: 2,
    question: 'Giá Liner của ghế CONTÉGO là bao nhiêu?',
    url: 'https://player.vimeo.com/video/439289762'
  },
  {
    index: 3,
    question: 'Arm rest và Arm tray của ghế Contego có gì đặc biệt?',
    url: 'https://player.vimeo.com/video/447309694'
  },
  {
    index: 4,
    question: 'Vì sao ghế Contego chọn fiberglass và stainless steel?',
    url: 'https://player.vimeo.com/video/447313066'
  },
  {
    index: 5,
    question: 'Foot Rest của ghế Contego có gì đặc biệt?',
    url: 'https://player.vimeo.com/video/447310355'
  }
]

const InfoPage = () => {
  const [isShown, setIsShown] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [warningMessage, setWarningMessage] = useTimeoutMessage('')
  const [onScroll, setOnScroll] = useState(false)

  const handleCallNow = e => {
    const currentHour = Number(
      new Date()
        .toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hour12: false })
        .split(':')[0]
    )

    if (currentHour >= 8 && currentHour <= 21) {
      callHandler(e.currentTarget.dataset.phoneNumber)
      navigate('/vi/thank-you')
    } else {
      // eslint-disable-next-line no-alert
      setWarningMessage(
        'Liên lạc ngoài giờ làm việc!!! Anh chị vui lòng để lại thông tin bên dưới, nhân viên tư vấn sẽ liên lạc. Giờ làm việc của nhân viên tư vấn 8AM-9PM (Giờ California)',
        20000
      )
    }
  }

  const handleFAQClick = React.useCallback(
    (e, titleProps) => {
      const { index } = titleProps
      const newIndex = activeIndex === index ? -1 : index
      setActiveIndex(newIndex)
    },
    [activeIndex]
  )

  const handleSuccess = useCallback(() => {
    navigate('/vi/thank-you')
  }, [])

  const toggleViewForm = useCallback(() => {
    setIsShown(isShown => !isShown)
  }, [])

  const handleCloseLeadForm = useCallback(() => {
    setIsShown(false)
  }, [])

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 120) {
        setOnScroll(true)
        setIsShown(false)
      } else if (window.pageYOffset === 0) {
        setOnScroll(false)
      }
    }
  })

  return (
    <div className="container-fluid">
      <SEO
        title="GIÁ TRỊ GHẾ CONTÉGO?"
        description="Vì sao đầu tư ghế pedicure spa massage chair của CONTEGO là tốt nhất."
        meta={[
          {
            name: 'keywords',
            content: 'GIÁ TRỊ ghế Contégo, lợi ích ghế Contego, hữu ích, lợi ích, ghế Contego'
          }
        ]}
      />

      <div
        className={classnames([
          'row shadow-sm',
          onScroll ? 'fixed-menu animated fadeIn' : 'relative-menu'
        ])}
      >
        <div className="col my-2 d-flex justify-content-between align-items-center flex-wrap">
          <img src={Logo} alt="contego logo" className="d-block" width="150px" height="auto" />

          <div>
            <Button icon onClick={toggleViewForm} className="my-2 rounded-pill shadow-sm" primary>
              <Icon name={isShown ? 'minus' : ''} />
              {isShown ? 'THU NHỎ' : 'LIÊN LẠC TÔI'}
            </Button>

            <Button
              className="call-now-btn rounded-pill text-white shadow-sm"
              data-phone-number="7148958865"
              onClick={handleCallNow}
            >
              GỌI NGAY <Icon name="phone" className="m-0" />
            </Button>
          </div>

          <Modal open={isShown}>
            <Modal.Header>
              <div className="d-flex justify-content-between">
                <img
                  src={Logo}
                  alt="contego logo"
                  className="d-block"
                  width="150px"
                  height="auto"
                />
                <Button icon color="red" onClick={handleCloseLeadForm}>
                  <Icon name="close" className="text-white" />
                </Button>
              </div>
            </Modal.Header>
            <Modal.Content>
              <LeadForm onSuccess={handleSuccess} />
            </Modal.Content>
          </Modal>
        </div>
      </div>

      <div className="row bg-light">
        <div className="col">
          <h1 className="text-center py-4 text-secondary">GIÁ TRỊ GHẾ CONTÉGO?</h1>
        </div>
      </div>

      <div className="row  shadow py-4">
        <div className="col ">
          <br />
          <Grid columns="1" stackable>
            <Grid.Column>
              <h2 className="text-center">TÍNH NĂNG HỮU ÍCH</h2>
              <p className="text-center">
                <strong className="text-secondary text-bold">
                  Disposable Jet, Infinity Overflow & Easy Drain
                </strong>{' '}
                phát triển ngành nail
              </p>
              <h3 className="text-secondary text-center my-0">&quot;NHANH SẠCH ĐẸP&quot;</h3>
            </Grid.Column>
          </Grid>

          <Grid stackable columns="16" padded>
            <Grid.Column
              widescreen="4"
              largeScreen="4"
              computer="8"
              tablet="16"
              mobile="16"
              className="py-4"
            >
              <div className="shadow pb-4 rounded">
                <div className="vimeo-video-container">
                  <iframe
                    title="Disposable Jet"
                    src="https://player.vimeo.com/video/430890746?autoplay=1"
                    frameBorder="0"
                    // allow="autoplay; fullscreen"
                    allowFullscreen
                    className="rounded vimeo-video"
                  />
                </div>
                <h2 className="text-center">DISPOSABLE JET</h2>
                <p className="text-center w-75 mx-auto">
                  Sử dụng một lần, vứt bỏ hoàn toàn đem lại sự an toàn cho khách và thợ
                </p>
              </div>
            </Grid.Column>

            <Grid.Column
              widescreen="4"
              largeScreen="4"
              computer="8"
              tablet="16"
              mobile="16"
              className="py-4"
            >
              <div className="shadow pb-4 rounded">
                <div className="vimeo-video-container">
                  <iframe
                    title="Infinity Overflow"
                    src="https://player.vimeo.com/video/430890794"
                    width="100%"
                    height="640"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullscreen
                    className="rounded vimeo-video"
                  />
                </div>
                <h2 className="text-center">INFINITY OVERFLOW</h2>
                <p className="text-center w-75 mx-auto">
                  Tiện lợi châm thêm nước sạch ngay trong lúc làm mà không lo nước tràn.
                </p>
              </div>
            </Grid.Column>

            <Grid.Column
              widescreen="4"
              largeScreen="4"
              computer="8"
              tablet="16"
              mobile="16"
              className="py-4"
            >
              <div className="shadow pb-4 rounded">
                <div className="vimeo-video-container">
                  <iframe
                    title="Easy Drain"
                    src="https://player.vimeo.com/video/430890829"
                    width="100%"
                    height="640"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullscreen
                    className="rounded vimeo-video"
                  />
                </div>
                <h2 className="text-center">EASY DRAIN</h2>
                <p className="text-center w-75 mx-auto">
                  Làm thủng liner để xả nước dơ mà không cần cho tay vào bồn
                </p>
              </div>
            </Grid.Column>

            <Grid.Column
              widescreen="4"
              largeScreen="4"
              computer="8"
              tablet="16"
              mobile="16"
              className="py-4"
            >
              <div className="shadow pb-4 rounded">
                <div className="vimeo-video-container">
                  <iframe
                    title="Others"
                    src="https://player.vimeo.com/video/430890843"
                    width="100%"
                    height="640"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullscreen
                    className="rounded vimeo-video"
                  />
                </div>

                <h1 className="text-center">OTHERS</h1>
                <p className="text-center w-75 mx-auto">
                  Chất lượng và những giá trị hữu hình của ghế Spa Contégo
                </p>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </div>

      <div className="row mt-4 py-4">
        <div className="col">
          <Grid columns="1" stackable className="pb-4">
            <Grid.Column>
              <h2 className="w-100 text-center pt-4">
                <strong>THIẾT KẾ TINH TẾ</strong>
              </h2>
              <p className="text-center">
                Ngoài những tính năng hữu ích, thiết kế ghế của CONTÉGO còn mang lại sự{' '}
                <strong className="text-secondary text-bold">TINH TẾ & SANG TRỌNG.</strong>
              </p>
            </Grid.Column>
          </Grid>

          <div className="vimeo-gallery">
            {_.reverse(chairVideos).map(({ i, videoLink, model }) => {
              return (
                <div className="shadow rounded mx-2 my-2" key={`${model}-${i}`}>
                  <iframe
                    title="Infinity Overflow"
                    src={videoLink}
                    className="vimeo-iframe"
                    frameBorder="0"
                    allow="fullscreen"
                    allowFullscreen
                  />
                  <div className="py-4">
                    <h2 className="text-center px-2 mb-0">
                      <strong>{model}</strong>
                    </h2>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="row  bg-light py-4">
        <div className="col ">
          <div className="px-3 py-4">
            <h1 className="text-secondary">
              CONTÉGO SPA CHAIR bảo vệ khách, tiện cho thợ, và giúp cho chủ.
            </h1>
            <h2>
              Để được tư vấn thêm
              <Button
                className="call-now-btn px-4 py-2 mx-2 my-2 rounded-pill text-large text-white shadow"
                data-phone-number="7148958865"
                onClick={handleCallNow}
              >
                GỌI NGAY <Icon name="phone" className="m-0" />
              </Button>
            </h2>

            {warningMessage !== '' && <Message content={warningMessage} error />}

            <h2> Hoặc để lại thông tin liên lạc bên dưới, chúng tôi sẽ gọi.</h2>

            <LeadForm onSuccess={handleSuccess} />
          </div>
        </div>
      </div>

      <div className="row  py-4 my-4">
        <div className="col ">
          <Grid columns="1" stackable>
            <Grid.Column>
              <h2 className="w-100 text-center">LỜI CHIA SẺ VỀ GHẾ SPA CONTÉGO</h2>
            </Grid.Column>
          </Grid>

          <Grid columns="1" stackable>
            <Grid.Column className="py-4">
              <div className="rounded">
                <h3 className="text-center text-uppercase text-secondary py-4 px-2">
                  <strong> Khách chia sẻ về an toàn vệ sinh trong dịch vụ pedicure.</strong>
                </h3>
                <div className="vimeo-video-container">
                  <iframe
                    title="Lời chia sẻ của khách hàng dùng ghế Contégo"
                    src="https://player.vimeo.com/video/439836176"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullscreen
                    className="rounded vimeo-video"
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid>

          <Grid columns="16" stackable>
            <Grid.Column
              widescreen="8"
              largeScreen="8"
              computer="16"
              tablet="16"
              mobile="16"
              className="py-4"
            >
              <div className=" rounded">
                <h3 className="text-center text-uppercase text-secondary py-4 px-2">
                  <strong>Lời chia sẻ của chủ tiệm khi đầu tư ghế Contégo</strong>
                </h3>
                <Carousel items={ownerReviews} />
              </div>
            </Grid.Column>

            <Grid.Column
              widescreen="8"
              largeScreen="8"
              computer="16"
              tablet="16"
              mobile="16"
              className="py-4"
            >
              <div className=" rounded">
                <h3 className="text-center text-uppercase text-secondary py-4 px-2">
                  <strong>Lời chia sẻ của anh chị thợ về sự hữu ích của ghế Contégo</strong>
                </h3>
                <Carousel items={techReviews} />
              </div>
            </Grid.Column>
          </Grid>

          <Grid columns="1" className="py-4 bg-light">
            <Grid.Column>
              <h1 className="text-secondary">HỎI VÀ ĐÁP</h1>
            </Grid.Column>

            <Grid.Column>
              <Accordion fluid>
                {faqs.map((faq, index) => {
                  return (
                    <div key={faq.question}>
                      <Accordion.Title
                        active={index === activeIndex}
                        index={faq.index}
                        data-index={faq.index}
                        onClick={handleFAQClick}
                      >
                        <h3>
                          <Icon name="dropdown" />
                          {faq.question}
                        </h3>
                      </Accordion.Title>

                      <Accordion.Content active={index === activeIndex}>
                        <div className="player-wrapper">
                          <Vimeo
                            className="react-player"
                            url={faq.url}
                            controls
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </Accordion.Content>
                    </div>
                  )
                })}
              </Accordion>
            </Grid.Column>
          </Grid>
        </div>
      </div>

      <div className="py-4">
        <a href="https://www.contegospa.com/privacy" className="my-4">
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

const LeadForm = ({ interestedTechs, onSuccess }) => {
  const recaptchaRef = React.createRef()

  const [errorMessage, setErrorMessage] = useTimeoutMessage('')
  const [successMessage, setSuccessMessage] = useTimeoutMessage('')

  const executeRecaptcha = () => {
    recaptchaRef.current.execute()
  }

  const handleExpired = () => {}

  const handleSubmission = React.useCallback(
    (values, actions) => {
      actions.setSubmitting(true)
      const { customerName, phoneNumber } = values

      // sanitize data before sending
      if (recaptchaRef.current.getValue()) {
        const sanitizedData = {
          customerName: _.escape(_.trim(customerName)),
          phoneNumber: _.escape(_.trim(phoneNumber)),
          techs: interestedTechs.join(','),
          status: 'new'
        }

        // submitting form value
        submitLandingPageForm(sanitizedData)
          .then(res => {
            setSuccessMessage(res.data.message)
          })
          .catch(error => {
            if (error.response) {
              setErrorMessage(error.response.data, 5000)
            } else {
              setErrorMessage('Something wrong happened. Please try again later!!!', 5000)
            }
          })
          .finally(() => {
            actions.setSubmitting(false)
            actions.resetForm()
            onSuccess && onSuccess()
          })
      }
    },
    [interestedTechs, onSuccess, recaptchaRef, setErrorMessage, setSuccessMessage]
  )

  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={handleSubmission}
      validationSchema={ContactSchema}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <>
          <Form noValidate className="attached fluid segment rounded shadow">
            {isSubmitting && <Progress percent={100} attached="top" active size="medium" />}
            {successMessage === '' && (
              <Grid stackable padded>
                <Grid.Row>
                  <Grid.Column>
                    <h3>
                      Nếu quý vị muốn biết thêm thông tin về sản phẩm của Contégo. Vui lòng để lại
                      thông tin bên dưới nhân viên của chúng tôi sẽ liên lạc.
                    </h3>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns="2">
                  <Grid.Column>
                    <TextInput
                      name="customerName"
                      label="Họ Tên"
                      placeholder="Vui lòng điền họ tên"
                      autoComplete="name"
                      required
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <TextInput
                      name="phoneNumber"
                      label="Số Điện Thoại"
                      placeholder="Vui lòng điền số điện thoại"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>

                {isValid && (
                  <Grid.Row>
                    <Grid.Column>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6Le0asMUAAAAALU8Bsu2PvNdUevkl9mM4s8YmT7F"
                        onChange={handleSubmit}
                        onExpired={handleExpired}
                        badge="inline"
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Grid.Column>
                    <h3>Anh chị có đồng ý cho chúng tôi liên lạc để tư vấn thêm không?</h3>
                    <Button
                      type="button"
                      primary
                      onClick={executeRecaptcha}
                      disabled={isSubmitting || !isValid}
                    >
                      Đồng Ý
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </Form>

          {errorMessage !== '' && (
            <Message
              attached="bottom"
              negative
              content="Xin lỗi hiện tại hệ thống đang gặp vấn đề trục trặc kỹ thuật. Quý anh chị vui lòng liên hệ trực tiếp với chúng tôi (714) 895-8865!!!"
            />
          )}

          {successMessage !== '' && (
            <Message attached="bottom" positive>
              <h1>THANK YOU!!!</h1>
              <p>Nhân viên tư vấn sẽ liên lạc với quý anh chị sớm.</p>
            </Message>
          )}
        </>
      )}
    </Formik>
  )
}

export default InfoPage
