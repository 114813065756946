import React from 'react'

import classNames from 'classnames'
import { Icon } from 'semantic-ui-react'
import ResEmbeddedYoutube from './ResEmbeddedYoutube'

const Carousel = ({ items, autoRotate, timeInterval }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)

  React.useEffect(() => {
    if (autoRotate) {
      const interval = setInterval(() => {
        if (activeIndex + 1 === items.length) {
          setActiveIndex(0)
        } else {
          setActiveIndex(activeIndex => activeIndex + 1)
        }
      }, timeInterval)
      return () => clearInterval(interval)
    }
  }, [activeIndex, items.length, autoRotate, timeInterval])

  const nextSlide = () => {
    if (activeIndex + 1 === items.length) {
      setActiveIndex(0)
    } else {
      setActiveIndex(activeIndex => activeIndex + 1)
    }
  }

  const previousSlide = () => {
    if (activeIndex === 0) {
      setActiveIndex(items.length - 1)
    } else {
      setActiveIndex(activeIndex => activeIndex - 1)
    }
  }

  return (
    <div className="container-fluid px-0 shadow-sm">
      {items.map(item => {
        return (
          <div
            key={`${item.index}`}
            className={classNames('row my-carousel-item', {
              active: item.index === activeIndex
            })}
          >
            <div className="col">
              {item.imgLink && (
                <img
                  src={item.imgLink}
                  alt="pedicure spa chair banner"
                  className="img-fluid animated fadeIn"
                />
              )}
              {item.videoLink && (
                <div className="vimeo-video-container">
                  <iframe
                    title="Infinity Overflow"
                    src={item.videoLink}
                    width="100%"
                    height="640"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullscreen
                    className="rounded vimeo-video"
                  />
                </div>
              )}
            </div>
          </div>
        )
      })}
      <div className="carousel-nav-indicator">
        <Icon
          name="angle left"
          className="carousel-pre-btn"
          color="grey"
          size="large"
          onClick={previousSlide}
        />
        <div className="d-flex justify-content-center flex-wrap px-4">
          {items.map(item => {
            return (
              <button
                key={item.index}
                aria-label="indicator-button"
                type="button"
                onClick={() => setActiveIndex(item.index)}
                className={classNames('carousel-indicator-btn rounded-circle m-1', {
                  active: activeIndex === item.index
                })}
              />
            )
          })}
        </div>
        <Icon
          name="angle right"
          className="carousel-next-btn"
          color="grey"
          size="large"
          onClick={nextSlide}
        />
      </div>
    </div>
  )
}

export default Carousel
